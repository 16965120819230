import React from "react"

export default function ScoreCard({ answer, value, icon, titleZh, titleEn }) {
  return (
      <div>
        <div className="col px-0 d-none d-lg-block">
          <div className="card mx-1">
            <div className="px-5 mt-4">
              <input className="form-control form-control-lg text-center" disabled type="text"
                     defaultValue={value ? value : 0}/>
            </div>
            <div className="card-body">
              <h2 className="card-title mt-3 pb-3">{answer}</h2>
              <img src={icon} className="w-auto mx-auto mb-3"/>
              <p className="card-text">
                <span className="d-block mb-2">{titleZh}</span>
                <span>{titleEn}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-lg-none">
            <hr className="pb-2"/>
            <div className="text-left">
                <span>{titleZh} </span>
                <span>{titleEn}</span>
            </div>
            <div className="progress my-1">
                <div className="progress-bar bg-danger" role="progressbar" style={{width:value ? value/12 * 100 + "%" : 0}}>{answer} - {value ? value : 0}</div>
            </div>
        </div>
      </div>
  )
}
