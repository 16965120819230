import React from 'react'
import ReactGA from 'react-ga'
import banner from './img/banner.png'
import mobileBanner from './img/banner_mobile.png'
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import Footer from './components/Footer'
import Landing from "./components/Landing"
import TestPage from "./components/TestPage"
import Result from "./components/Result"
import coupleQuestionData from './data/couple'
import singleQuestionData from './data/single.json'
import teenQuestionData from './data/teen.json'

const defaultAnswers = coupleQuestionData.reduce((result, item) => {
  result[item.name] = null
  return result
}, {})

const answers = defaultAnswers
// const answers = {
//   q1: "E", q2: "D", q3: "B", q4: "D", q5: "E", q6: "B", q7: "C", q8: "E", q9: "B", q10: "A",
//   q11: "B", q12: "D", q13: "A", q14: "B", q15: "A", q16: "B", q17: "D", q18: "A", q19: "D", q20: "D",
//   q21: "B", q22: "C", q23: "C", q24: "B", q25: "D", q26: "E", q27: "A", q28: "C", q29: "D", q30: "E",
// }

// const answers = {
//   q1: "E", q2: "D", q3: "B", q4: "D", q5: "E", q6: "B", q7: "C", q8: "E", q9: "B", q10: "A",
//   q11: "B", q12: "D", q13: "A", q14: "B", q15: "A", q16: "B", q17: "D", q18: "A", q19: "D", q20: "D",
//   q21: "B", q22: "C", q23: "C", q24: "B", q25: "D", q26: "E", q27: "A", q28: "C", q29: "D", q30: null,
// }


export const AnswerContext = React.createContext({
  answers
})

function App() {
  const setAnswers = (name, ans) => {
    updateAnswers(prevState => {
      const { answers } = prevState
      const newAnswers = { ...answers, [name]: ans }
      return { ...prevState, answers: newAnswers }
    })
  }

  const answerState = {
    answers,
    setAnswers
  }

  const [state, updateAnswers] = React.useState(answerState)

  const [showResults, updateShowResults] = React.useState(false)

  const [showLanding, updateShowLanding] = React.useState(true)

  const [selectedQuestionSet, updateSelectedQuestionSet] = React.useState("couple")

  function handleOnSubmitClicked() {
    updateShowResults(true)
    window.scrollTo(0, 0)
  }

  function onResetClicked() {
    updateShowResults(false)
    updateAnswers(p => {
      return { ...p, answers: defaultAnswers }
    })
    window.scrollTo(0, 0)
  }

  return (
    <AnswerContext.Provider value={state}>
      <main>
        <div className="text-center d-md-block d-none">
          <img className="w-100" src={banner}/>
        </div>
        <div className="text-center d-md-none">
          <img className="w-100" src={mobileBanner}/>
        </div>
        <div className="container mb-5">
          {body(showLanding, showResults, state, onResetClicked, handleOnSubmitClicked, updateShowLanding, selectedQuestionSet, updateSelectedQuestionSet)}
        </div>
        <Footer/>
      </main>
    </AnswerContext.Provider>
  )
}

function body(showLanding, showResults, state, onResetClicked, handleOnSubmitClicked, updateShowLanding, selectedQuestionSet, updateSelectedQuestionSet) {

  function update(type) {
    ReactGA.event({
      category: 'Button',
      action: 'Selected Type',
      value: type
    });
    updateShowLanding(false)
    updateSelectedQuestionSet(type)
  }

  if (showLanding)
    return <Landing onButtonClicked={update}/>
  if (showResults)
    return <Result answers={state.answers} onResetClicked={onResetClicked}/>
  else
    return <TestPage handleOnSubmitClicked={handleOnSubmitClicked} questionData={getQuestionSet(selectedQuestionSet)}/>
}

function getQuestionSet(selectedQuestionSet) {
  if (selectedQuestionSet === "couple")
    return coupleQuestionData
  else if (selectedQuestionSet === "single")
    return singleQuestionData
  else if (selectedQuestionSet === "teen")
    return teenQuestionData
}

export default App
