import React from 'react';

export default function Footer() {
  return (
    <footer className="py-4 d-none">
      <div className="container text-center">
          <div>2020 &copy; 天主教婚前准备课程 | Catholic (Mandarin) Marriage Preparation Course. All rights
              reserved.</div>
          <div className="d-none"><small className="text-monospace text-muted">Developed by William T. &amp; Stella L.</small></div>
      </div>
    </footer>
  )
}
