import React from "react"

export default function ResultCard({ answer, titleEn, titleZh, descriptions, icon }) {
  return (
    <div className="content-result text-center p-4 mb-3 card border-0">
      <h2>{answer}</h2>
      <img src={icon} className="w-auto mx-auto mb-3"/>
      <h4>{titleZh}</h4>
      <h4>{titleEn}</h4>
      <hr/>
      {descriptions.map(d => (<h5 key={d}>{d}</h5>))}
    </div>
  )
}
