import React from 'react'
import { AnswerContext } from "../App"
import { scroller } from 'react-scroll'

export default function Question({ id, name, questionZh, questionEn, answer, checked }) {
  const { setAnswers } = React.useContext(AnswerContext)

  function onRadioButtonChanged(e, data) {
    setAnswers(data.name, data.answer)
    const qustionNumber = parseInt(data.name.replace("q", ""))
    if (qustionNumber !== 30) {

      scroller.scrollTo("q" + (qustionNumber + 1), {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -15
      })
    }
  }

  return (
    <div className={checked ? "input-group selected" : "input-group"}>
      <input id={id} data-ans={answer} name={name} type="radio" checked={checked}
             onChange={e => onRadioButtonChanged(e, { name, answer })}/>
      <span className="position-absolute ans-indicator">{answer}</span>
      <label htmlFor={id}>
        <span className="d-block mb-2">{questionZh}</span>
        <span>{questionEn}</span>
      </label>
    </div>
  )
}
