import React from 'react'
import ResultCard from "./ResultCard"
import answersJson from "../data/answer"
import ScoreCard from "./ScoreCard"

export default function Result({ answers, onResetClicked }) {
  function renderScoreCard(score) {
    return Object.entries(answersJson).map(([k, v]) => {
      return (
        <ScoreCard key={k} answer={k} value={score[k]} icon={"img/" + answersJson[k].icon} titleZh={v.title.zh} titleEn={v.title.en}/>)
    })
  }

  function renderResultCard(highest, groupAnswers) {
    const filtered = Object.entries(groupAnswers).filter(([k, v]) => (v === highest[1]))
    return filtered.sort((a, b) => (b[0] > a[0] ? -1 : 1)).map(([k, n]) => {
      return <ResultCard key={k} answer={k} icon={"img/" + answersJson[k].icon} descriptions={answersJson[k].description} titleEn={answersJson[k].title.en} titleZh={answersJson[k].title.zh}/>
    })
  }

  const groupAnswers = Object.values(answers).reduce((results, ans) => {
    if (results[ans] === undefined) {
      results[ans] = 1
    } else {
      results[ans]++
    }
    return results
  }, {})

  const sorted = Object.entries(groupAnswers).sort(function (a, b) {
    return b[1] - a[1]
  })

  return (
    <section className="result py-5 px-3 position-relative">
      <h2 className="text-center mb-4">测试分析结果 / Result</h2>
      <h4 className="text-center mb-3">您的主要爱的语言是：/ Your main love language is</h4>
      <div className="card-deck mb-4">{renderResultCard(sorted[0], groupAnswers)}</div>
      <h4 className="text-center mb-3">爱的语言-分数的分布 / Love languages - distribution of points</h4>
      <div className="score-card row row-cols-1 row-cols-lg-5 text-center px-3">{renderScoreCard(groupAnswers)}</div>
      <button className="btn btn-reset btn-block btn-lg my-3" onClick={onResetClicked}>重新测试 / Retest</button>
    </section>
  )
}
