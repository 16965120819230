import React from 'react';

export default function Landing({ onButtonClicked }) {
  console.log(onButtonClicked)
  return (
    <div className="text-center mt-3">
      <h4>为什么有的人喜欢听甜言蜜语，有的人喜欢默默做事，有的人喜欢收礼品，有的人看重陪伴？因为每个人爱和接受爱的方式不一样。爱有五种语言，你的爱的语言是什么？</h4>
      <h5 className="pb-4">Why do some people like to listen to sweet talk, some like to do things silently, some like to receive gifts,
        and some value accompany? Because everyone loves and receives love in different ways. There are five languages of love, what is your language of love?</h5>
      <h4>请选择以下其中一个合适的选项进行测试:</h4>
      <h5 className="pb-3">Please choose one of the following suitable options to test:</h5>
      <button type="button" className="btn btn-outline-danger btn-lg mb-2" onClick={e => onButtonClicked("couple")}>
        已婚 / Married
      </button>
      <button type="button" className="btn btn-outline-success btn-lg mx-4 mb-2"
              onClick={e => onButtonClicked("single")}>单身 / Single
      </button>
      <button type="button" className="btn btn-outline-info btn-lg mb-2" onClick={e => onButtonClicked("teen")}>孩子 /
        Child
      </button>
    </div>
  )
}
