import React from 'react'
import Question from './Question'
import { AnswerContext } from '../App'

export default function TestPage({ handleOnSubmitClicked, questionData }) {

  const { answers } = React.useContext(AnswerContext)

  const unAnswered = Object.entries(answers).reduce((results, [k, v]) => {
    if (v === null)
      results.push(k)
    return results

  }, [])

  function renderQuestion(q) {
    return (
      <li id={q.name} key={q.name}>
        <div className="numbering-border-bottom"/>
        <Question id={q.name + "-q1"} name={q.name} answer={q.q1.ans} questionEn={q.q1.en} questionZh={q.q1.zh}
                  checked={answers[q.name] === q.q1.ans}/>
        <Question id={q.name + "-q2"} name={q.name} answer={q.q2.ans} questionEn={q.q2.en} questionZh={q.q2.zh}
                  checked={answers[q.name] === q.q2.ans}/>
      </li>
    )
  }

  function renderUnAnsweredList(item) {
    return (
      [<a key={item} className="font-weight-bold" href={"#" + item}>{item.replace('q', '')}</a>,
        <span key={item + "-comma"}>, </span>]
    )
  }

  return (
    <section className="mb-5">
      <h4>这个测验包括30道题目，每一道题目都有两句话。仔细读这两句话，从中选出最能反映你想法的句子。有些题目可能两个句子你都想选，不过还是请你<span className="font-weight-bold">只选一个</span>，以便测出更精确的结果。</h4>
      <h5>This test consists of 30 questions, each of which has two sentences. Read these two sentences carefully and choose the one that best reflects your thoughts.
        For some topics, you may want to choose both sentences, but you are still asked to choose <span className="font-weight-bold">ONLY ONE</span> in order to get a more accurate result.</h5>
      <form className="test">
        <ol className="mb-5">
          {questionData.map(q => renderQuestion(q))}
        </ol>
        <button className="btn btn-submit btn-block btn-lg mb-3"
                disabled={unAnswered.length > 0}
                onClick={handleOnSubmitClicked}>测试分析 / Submit
        </button>
      </form>
      {unAnswered.length > 0 && (<div className="alert alert-danger" role="alert">
        <span>你还有第 </span>
        {unAnswered.map(item => renderUnAnsweredList(item)).flat().slice(0, -1)}
        <span> 题还没回答。</span>
      </div>)}
    </section>
  )
}
